<template>
  <div>
    <datepicker
      :id="id"
      :value="valueFormatted"
      :inline="inline"
      :name="name"
      :format="format"
      :language="language"
      :disabled="disabled"
      :typeable="typeable"
      :placeholder="placeholder"
      :full-month-name="true"
      :input-class="inputClass"
      :wrapper-class="'form-group'"
      :required="required"
      :readonly="false"
      @selected="handleDatepickerSelected"
    ></datepicker>
  </div>
</template>

<script>
import Datepicker from '@hokify/vuejs-datepicker'
import '@hokify/vuejs-datepicker/dist/vuejs-datepicker.css'
import { ptBR } from '@hokify/vuejs-datepicker/dist/locale'
import moment from 'moment'

/**
 * @see https://www.npmjs.com/package/vuejs-datepicker
 */
export default {
  components: {
    Datepicker
  },

  props: {
    /**
     * Date value of the datepicker
     */
    value: {
      type: [Date, String],
      default: ''
    },

    /**
     * To show the datepicker always open
     */
    inline: {
      type: Boolean,
      default: false
    },

    /**
     * Input placeholder text
     */
    placeholder: {
      type: String,
      default: 'dd/mm/aaaa'
    },

    /**
     * Input name property
     */
    name: {
      type: String,
      default: () => {
        return `datepicker-name-${Date.now()}`
      }
    },

    /**
     * Input ID property
     */
    id: {
      type: String,
      default: () => {
        return `datepicker-id-${Date.now()}`
      }
    },

    /**
     * Date formatting string or function
     */
    format: {
      type: String,
      default: 'dd/MM/yyyy'
    },

    /**
     * If true, disable Datepicker on screen
     */
    disabled: {
      type: Boolean,
      default: false
    },

    /**
     * If true, allow the user to type the date
     */
    typeable: {
      type: Boolean,
      default: false
    },

    /**
     * If true, turns the Datepicker required
     */
    required: {
      type: Boolean,
      default: false
    },

    inputClass: {
      type: String,
      default: 'form-control'
    }
  },

  data() {
    return {
      language: ptBR
    }
  },

  computed: {
    valueFormatted() {
      return moment(this.value, 'YYYY-MM-DD').format()
    }
  },

  watch: {
    required: {
      handler() {
        this.handleReadonly()
      }
    }
  },

  mounted() {
    this.handleReadonly()
  },

  methods: {
    /**
     * A date has been selected
     * @param {Date} date Selected date in calendar
     * @public
     */
    handleDatepickerSelected(date) {
      this.$emit('selected', moment(date).format('YYYY-MM-DD'))
    },

    /**
     * Handle readonly when the datepicker is required
     */
    handleReadonly() {
      const element = document.getElementById(this.id)

      if (this.required) {
        element.removeAttribute('readonly')

        element.addEventListener('focus', () => {
          setTimeout(() => {
            element.setAttribute('readonly', true)
          }, 1000)
        })

        element.addEventListener('focusout', () => {
          element.removeAttribute('readonly')
        })
      }
    }
  }
}
</script>

<style lang="scss">
.vdp-datepicker {
  &.form-group {
    div {
      &:first-child {
        position: relative;
        &:after {
          content: '\f073';
          font-family: 'Font Awesome 5 Pro';
          font-weight: 200;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 40px;
          font-size: 20px;
          line-height: 45px;
          display: inline-block;
          padding: 0px 11px;
          pointer-events: none;
        }
      }
    }

    .form-control {
      background-color: #fff;
      opacity: initial;

      &:disabled {
        background-color: #ebedef;
        opacity: 1;
      }
    }
  }

  .vdp-datepicker__calendar {
    .cell {
      position: relative;
      border-radius: 50%;
      background: transparent !important;
      border: none !important;

      &:hover {
        color: $dark;
      }

      &:after {
        content: '';
        border-radius: 50% !important;
        width: 32px !important;
        height: 32px !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      &.today {
        &:after {
          background: $gray-100;
        }
      }

      &.selected {
        color: #fff;

        &:after {
          background: $primary;
        }
      }
    }
  }
}
</style>
